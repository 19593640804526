.sidebar-container {
    align-self: stretch;
    position: relative;
    .sidebar {
        width: 200px;
        box-shadow: 0px 0px 1px black;
        background-color: white;
        margin: 0.5rem 0px;
        .sidebar-username {
            background-color: #4285F4;
            font-size: 1.5rem;
            color: white;
            padding: 1rem;
            text-align: center;
        }
        .sidebar-links {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 0.5rem;
            padding: 1rem;
            .sidebar-link {
                transition: 0.3s all ease;
                width: 100%;
                padding: 0.5rem;
                text-align: center;
                border-radius: 4px;
                border: 2px solid #4285F4;
                color: #4285F4;
                .sidebar-link-container {
                    display: flex;
                    gap: 1rem;
                    margin: auto;
                    align-items: center;
                    width: min(200px, 100%);
                    text-align: center;
                    justify-content: center;
                }
            }
            .selected-age-group {
                background-color: #4285F4;
                color: white;
            }
        }
    }
    .sidebar-toggle {
        display: none;
    }
    @media (max-width: 968px) {
        .sidebar {
            transition: 0.5s left;
            position: fixed;
            z-index: 5;
            border-radius: 0px 8px 8px 0px;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            min-height: 300px;
            height: 100vh;
            top: 0px;
            padding: 0px;
            margin: 0px;
            overflow-y: auto;
        }
        .sidebar-toggle {
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            border-radius: 0px 8px 8px 0px;
            transition: 0.5s left;
            position: fixed;
            height: 50px;
            width: 50px;
            background-color: #4285F4;
            top: calc(50vh - 25px);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2rem;
            color: white;
            z-index: 5;
        }
        .show-sidebar-toggle {
            left: 200px;
        }
        .hide-sidebar-toggle {
            left: 0px;
        }
        .show-sidebar {
            left: 0px;
        }
        .hide-sidebar {
            left: -200px;
        }
    }
}
