@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');

* {
    scroll-behavior: smooth;
  box-sizing: border-box;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
letter-spacing: 0.01075rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  overflow-x: hidden;
  min-height: 100vh;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
    @media (max-width: 1168px) {
        font-size: 15px;
    }
    @media (max-width: 968px) {
        font-size: 14px;
    }
    @media (max-width: 668px) {
        font-size: 13px;
    }
    @media (max-width: 468px) {
        font-size: 12px;
    }
    @media (max-width: 268px) {
        font-size: 11px;
    }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #FFFFFF;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #4285F4;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input[type='date'] {
    text-transform: uppercase;
}

ul {
    margin: 0px;
    list-style-type: none;
    padding: 0px;
}

a, input[type='button'], button, svg {
    cursor: pointer;
}

a {
    color: black;
}

a, input, select, textarea, button {
    border: 0px;
    outline: none;
    text-decoration: none;
}

p, h1, h2, h3, h4, h5, h6, span, pre, a {
  margin: 0px;
  overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

img {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.alert {
    font-size: 0.9rem;
    z-index: 10;
    position: fixed;
    top: 1.5rem;
    border-radius: 3px;
    box-shadow: 0px 0px 1px black;
    padding: 0.5rem;
    left: calc(50% - 250px);
    color: #FFFFFF;
    text-align: center;
    min-width: 500px;
    max-width: auto;
    font-weight: bold;
}

.loading-alert {
    font-size: 0.9rem;
    z-index: 10;
    position: fixed;
    top: 1rem;
    border-radius: 5px;
    box-shadow: 0px 0px 3px black;
    padding: 0.5rem;
    left: calc(50% - 50px);
    color: #FFFFFF;
    text-align: center;
    width: 100px;
    height: 35px;
}

.loading-spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    position: fixed;
    top: 1rem;
    left: calc(50% - 25px);
    background-color: #4285F4;
    z-index: 10;
    box-shadow: 0px 0px 1px black;
}
.loading-spinner {
    border: 3px solid white;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border-color: white white transparent transparent;
    animation: spinny 1s infinite linear;
}
@keyframes spinny {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

.gold-button {
    text-decoration: none;
    color: #FFFFFF;
    text-align: center;
    height: 35px;
    padding: 0px 1.5rem;
    background: #4285F4;
    border-radius: 3px;
    display: block;
    font-size: 0.95rem;
    font-weight: bold;
}

.gold-border-button {
    text-decoration: none;
    text-align: center;
    height: 35px;
    padding: 0px 1.5rem;
    border-radius: 3px;
    display: block;
    background-color: transparent;
    border: 3px solid #4285F4;
    color: #4285F4;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.customer-field {
    border-bottom: 1px dotted #DBDBDB;
    padding: 1rem 0.5rem !important;
    &:last-child {
        border: 0px;
    }
}