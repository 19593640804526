.form {
    width: min(400px, 95%);
    background-color: #F8F7F7;
    margin: auto;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
    border-radius: 5px;
    border: 1px solid #DBDADA;
    margin: 3rem auto;
}
.form-container {
    h2 {
        font-weight: normal;
        text-align: center;
    }
    input, button {
        height: 35px;
        border-radius: 5px;
        width: 100%;
    }
    .input-field {
        label {
            display: flex;
            margin-bottom: 0.5rem;
            font-size: 0.9rem;
            justify-content: space-between;
            align-items: center;
            span {
                font-size: 0.8rem;
                font-weight: bold;
                color: #1595DC;
                text-decoration: underline;
            }
        }
        input {
            height: 40px;
            color: black;
            font-weight: 500;
            background-color: #DBDBDB;
            border: 0px;
            padding: 0px 1rem;
        }
        input[type='date'] {
            text-transform: uppercase;
        }
    }
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        font-weight: bold;
        height: 35px !important;
    }
    .secondary-text {
        font-size: 0.8rem;
        color: #808080;
    }
    .password-field {
        position: relative;
        input {
            padding-right: 2.75rem;
        }
        svg {
            font-size: 1.5rem;
            position: absolute;
            top: 2rem;
            right: 0.75rem;
        }
    }
}
