.dashboard {
    display: flex;
    align-items: stretch;
    min-height: calc(100vh - 200px);
    .dashboard-content {
        margin: 0.5rem;
        width: 100%;
        .dashboard-title {
            display: flex;
            background-color: rgba(66, 133, 244, 0.1);
            align-items: center;
            width: 100%;
            padding: 1rem;
            justify-content: space-between;
            h2 {
                display: flex;
                gap: 0.5rem;
                font-size: 1.5rem;
                align-items: center;
                img {
                    height: 15px;
                }
            }
        }
        .dashboard-filter {
            position: relative;
            display: inline;
            .dashboard-filter-inner {
                top: 1.4rem;
                display: none;
                background: white;
                position: absolute;
                border-radius: 5px;
                left: 0px;
                z-index: 3;
                border: 2px solid #DBDBDB;
                font-size: 0.8rem;
            }
            &:hover {
                .dashboard-filter-inner {
                    display: flex;
                }
            }
        }
        .backdrop {
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100vw;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 0;
            z-index: -3;
            .backdrop-container {
                background-color: white;
                padding: 1rem;
                border-radius: 3px;
                color: black;
                .backdrop-buttons {
                    margin-top: 1rem;
                    display: flex;
                    justify-content: center;
                    gap: 0.5rem;
                    input {
                        color: white;
                        border-radius: 5px;
                        box-shadow: 0px;
                    }
                }
            }
        }
        .show-backdrop {
            z-index: 6;
            opacity: 1;
        }
        .refresh {
            display: flex;
            align-items: center;
            svg {
                transform-origin: center;
                transition: 0.3s transform;
                &:hover {
                    transform: rotate(45deg);
                }
            }
        }
        .refreshing {
            animation: 0.5s spin linear infinite;
        }
        @keyframes spin {
            0% {transform: rotate(0deg);}
            100% {transform: rotate(360def);}
        }
    }
    .dashboard-action {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        background-color: white;
        border-radius: 8px;
        padding: 0.5rem;
        text-align: center;
        margin: auto;
        justify-content: center;
    }
    @media (max-width: 968px) {
        .dashboard-table {
            table {
                th, td {
                    min-width: 90px;
                }
            }
        }
    }
}
