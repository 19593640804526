.our-library {
    .add-books-csv {
        padding: 1.5rem 0px 0px 1.5rem;
        display: flex;
        align-items: center;
        gap: 2em;
        .downloadCSV{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: auto;
            background-color: #4285F4;
            padding: 1.25em;
            border-radius: 3px;
            color: white;
            font-size: .95rem;
            font-weight: 900;
        }

                
        input, h3 {
            text-align: center;
        }
        .column-names {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            p {
                border: 1px solid #bcbcbc;
                padding: 0.25rem 0.5rem;
                border-radius: 4px;
                &:first-child {
                    border: 0px;
                }
            }
        }
    }
    .books {
        padding: 1.5rem 1rem;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: center;
        .book {
            position: relative;
            height: auto;
            background-color: rgba(66, 133, 244, 0.1);
            padding: 1rem 1rem 1rem 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 12.5rem;
            > p {
                display: -webkit-box;
                overflow : hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;  
                font-size: 1rem;
            }
            .book-image {
                overflow: hidden;
                display: flex;
                justify-content: center;
                height: 10.625rem;
                > img {
                    height: 10.625rem;
                }
            }
            .book-details {
                margin-top: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;
                .book-detail {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                }
                &:nth-child(2) {
                    p {
                        color: #4285F4;
                    }
                }
            }
            .book-overlay {
                transition: 0.3s all ease;
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.7);
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                gap: 1rem;
                left: 0px;
                top: 0px;
                font-size: 0.8rem;
                z-index: 3;
                border-radius: 3px;
                > * {
                    color: hsl(0, 0%, 100%);
                }
                .book-overlay-row {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                }
                .gold-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0.25rem;
                    border: 0px;
                    padding: 0.25rem 0.5rem;
                    border-radius: 3px;
                    height: 30px;
                    width: 70px;
                    font-size: 0.8rem;
                    svg {
                        font-size: 1.25rem;
                    }
                }
                p {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0.5rem;
                    span {
                        background-color: #4285F4;
                        display: block;
                        border-radius: 3px;
                        padding: 0.25rem 0.5rem;
                    }
                }
            }
            .book-not-available {
                border-radius: 3px;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                top: 0px;
                left: 0px;
                p {
                    border-radius: 3px;
                    margin: 0.5rem auto;
                    background-color: #4285F4;
                    color: white;
                    padding: 0.25rem;
                    font-size: 0.8rem;
                    text-align: center;
                    width: 60%
                }
            }
            &:hover {
                .book-overlay {
                    opacity: 1;
                }
            }
        }
    }
    .book-sections {
        display: flex;
        margin: 1.5rem 1.5rem 0px 1.5rem;
        gap: 1rem;
        flex-wrap: wrap;
        .book-section {
            text-decoration: underline;
            cursor: pointer;
        }
        .selected-book-section {
            color: #4285F4;
            font-weight: bold;
        }
    }
    .book-categories {
        position: relative;
        width: 250px;
        margin: 1.5rem 1.5rem 0px 1.5rem;
        .category-button-container {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            .category-button {
                border: 1px solid #BCBCBC;
                border-radius: 3px;
                height: 35px;
                width: min(250px, 95%);
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0px 0.5rem;
            }
        }
        .category-dropdown {
            position: absolute;
            background-color: white;
            z-index: 5;
            width: min(100vw, 95%);
            max-height: 400px;
            overflow-y: auto;
            transform: scaleY(0);
            border-radius: 3px;
            border: 1px solid #BCBCBC;
            top: 40px;
            padding: 1rem;
            overflow-x: hidden;
            .category-dropdown-list {
                display: flex;
                flex-wrap: wrap;
                gap: 1.25rem;
                align-items: flex-start;
            }
            .book-category {
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;
                position: relative;
                width: 250px;
                b {
                    color: #4285F4;
                    font-size: 0.8rem;
                    width: fit-content;
                }
                .book-category-list {
                    margin-top: 1rem;
                    display: flex;
                    flex-direction: column;
                    gap: 0.75rem;
                    grid-column: 1/3;
                    .category {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: #636363;
                        font-size: 0.8rem;
                        cursor: pointer;
                        span {
                            padding: 0.25rem;
                            border-radius: 3px;
                        }
                        svg {
                            display: none;
                        }
                        &:hover {
                            svg {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
        .show-category-dropdown {
            transform: scaleY(1);
        }
        .hide-category-dropdown {
            transform: scaleY(0);
        }
    }
    .update-book-quantity-container {
        position: fixed;
        top: 0px;
        left: 0px;
        background-color: rgba(0, 0, 0, 0.7);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 7;
        form {
            border-radius: 3px;
            padding: 1rem;
            background-color: white;
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
            h3, p {
                text-align: center;
            }
            p {
                margin: auto;
                width: 200px;
                font-size: 0.9rem;
            }
            .input-field {
                display: flex;
                flex-direction: column;
                gap: 0.25rem;
                label {
                    font-size: 0.9rem;
                }
                input {
                    border-bottom: 2px solid #4285F4;
                    font-size: 1rem;
                    height: 35px;
                    padding: 0px 0.5rem;
                }
            }
            input[type='button'] {
                background-color: #4285F4;
                padding: 0.25rem 0px;
                border-radius: 3px;
                color: white;
            }
        }
    }
}
.add-books {
    .backdrop-container {
        width: min(450px, 90%);
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding: 1.5rem !important;
        .input-field {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 0.5rem;
            label {
                font-size: 0.9rem;
            }
            input {
                height: 40px;
                font-weight: 500;
                background-color: lightgray;
                border: 0px;
                padding: 0px 1rem;
                text-align: left;
                color: black;
            }
            p {
                font-size: 0.8rem;
                color: #636363;
            }
        }
        button {
            margin: 0.5rem auto !important; 
            width: 80% !important;
        }
        .add-results {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            text-align: left;
            .result {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                p {
                    height: 40px;
                    background-color: lightgray;
                    padding: 0.5rem 1rem;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}