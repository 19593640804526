.categories-container {
    .add-category {
        margin: 1.5rem;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 1rem;
        .add-category-form {
            display: flex;
            gap: 0.5rem;
            .add-category-input {
                display: flex;
                flex-direction: column;
                gap: 0.25rem;
                span {
                    font-size: 0.8rem;
                }
                input {
                    background-color: #DBDBDB;
                    padding: 0px 0.5rem;
                    height: 40px;
                    border-radius: 3px;
                }
            }
        }
    }
    .categories {
        margin: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        .category {
            position: relative;
            width: 200px;
            background-color: rgba(66, 133, 244, 0.1);
            padding: 1rem;
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 1rem;
            textarea {
                resize: none;
                padding: 0.5rem;
                border-radius: 3px;
                border: 3px solid #4285F4;
                height: 70px;
            }
            .category-ages {
                display: flex;
                justify-content: space-between;
                gap: 0.5rem;
                .category-age {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0.5rem;
                    span {
                        font-size: 0.75rem;
                    }
                    p, input {
                        width: auto;
                        max-width: 70px;
                        text-align: center;
                        background-color: #4285F4;
                        padding: 0.125rem 0.5rem;
                        color: white;
                        border-radius: 3px;
                        margin: auto;
                    }
                }
            }
            .category-button {
                transform: scale(0);
                position: absolute;
                background-color: transparent;
                top: -0.45rem;
                transition: 0.1s all ease;
            }
            .category-edit-button {
                right: 1.25rem;
                color: green;
            }
            .category-delete-button {
                right: 0px;
                svg {
                    color: red;
                }
            }
            &:hover {
                .category-button {
                    transform: scale(1);
                }
            }
        }
    }
}