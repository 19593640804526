.current-book {
    display: flex;
    margin: 3rem auto;
    align-items: flex-start;
    width: min(1000px, 90%);
    gap: 3rem;
    .book-image {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 40%;
        min-height: 300px;
        img {
            border-radius: 3px;
            width: 100%;
        }
        .upload-book-image {
            position: absolute;
            width: 100%;
            height: 100%;
            border: 3px solid #4285F4;
            top: 0px;
            left: 0px;
            background-color: rgb(0, 0, 0);
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            flex-direction: column;
            gap: 0.5rem;
            font-size: 0.75rem;
            input {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0px;
                opacity: 0;
            }
            svg {
                font-size: 4rem;
            }
        }
    }
    .book-video{
        background-color: black;
        .video{
            height: 40vh;
            width: 100vw;
          

        }
    }
    .upload-video-button
    {
        width: 100%;
        height: 40px;
        background-color: #4285F4;
        color: white;
        z-index: 2;
        text-align: center;
    }
    .book-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 60%;
        gap: 1rem;
        .book-detail {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            span {
                font-size: 0.8rem;
            }
            input, p, textarea {
                font-size: 0.9rem;
                background-color: #DBDBDB;
                padding: 0.75rem 1rem;
                border-radius: 3px;
                border: 3px solid transparent;
            }
            input, textarea {
                border: 3px solid #4285F4;
            }
            textarea {
                resize: none;
                height: 125px;
            }
        }
        .book-detail-list {
            width: 100%;
            display: flex;
            gap: 1rem;
            .book-detail {
                width: 50%;
            }
        }
        .book-category-list {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            border-radius: 3px;
            .book-category {
                font-size: 0.9rem;
                background-color: #DBDBDB;
                padding: 0.75rem 1rem;
            }
        }
        .book-category-input {
            display: flex;
            background-color: #DBDBDB;
            border: 3px solid #4285F4;
            border-radius: 3px;
            height: 40px;
            select {
                padding: 0px 0.5rem;
                width: 150px;
                width: 50%;
                background-color: transparent;
                border-right: 3px solid #4285F4;
                &:last-child {
                    border: 0px;
                }
            }
        }
        .add-update-actions {
            display: flex;
            gap: 0.5rem;
        }
        .remove-category-button {
            margin-left: -0.5rem;
            background-color: transparent;
            color: red;
        }
        .add-category-button {
            background-color: transparent;
            font-size: 1.5rem;
            width: auto;
            text-align: left;
        }
        .blue-button {
            width: 50%;
        }
    }
    @media (max-width: 568px) {
        flex-direction: column;
        .book-image, .book-details {
            width: 100%;
        }
    }
}
.file-upload {
    position: relative;
    display: inline-block;
  }
  
  .file-input {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  
  .file-label {
    display: inline-block;
    padding: 8px 16px;
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    
  }
 
.image-container {
    position: relative;
    display: inline-block;
  }
  
  .image-container img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  
  .overlay {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8); /* Adjust background as needed */
    padding: 8px; /* Adjust padding as needed */
  }
  .overlay svg {
    width: 24px; /* Adjust size of SVG */
    height: 24px; /* Adjust size of SVG */
    fill: #333; 
}
.video{
    font-size: 16px;
 
    @media (max-width: 568px) {
        max-width: 350px;
    }
    @media (min-width: 568px) {
        max-width: 230px;
    }
    @media (min-width: 1600px) {
        max-width: 370px;
    }
    
}
  